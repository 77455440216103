



























































































































































































































.modal-wrapper {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .mask {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
  }
  .login-wrapper {
    box-sizing: border-box;
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 367px;
    height: 339px;
    padding: 30px 60px 46px;
    border-radius: 9px;
    background-color: #fff;
    .title {
      margin-bottom: 15px;
      text-align: center;
      line-height: 28px;
      font-weight: 400;
      font-size: 20px;
      color: #13203d;
    }
    .form-item {
      margin-bottom: 30px;
      .label {
        line-height: 18px;
        font-size: 13px;
        color: #767e92;
      }
      .input {
        display: flex;
        align-items: center;
        height: 30px;
        border-bottom: 1px solid #efefef;
        &.focus {
          border-bottom: 1px solid rgba(48, 110, 237, 1);
        }
        .icon {
          width: 15px;
          height: 17px;
          margin-right: 14px;
        }
        input {
          flex: 1;
          border: none;
          color: #13203d;
          font-size: 13px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            color: #aaabb6;
            font-size: 13px;
          }
        }
        .send {
          color: #ff6000;
          font-size: 13px;
          &.pointer {
            cursor: pointer;
          }
        }
      }
    }
    .btn {
      width: 100%;
      height: 34px;
      margin-top: 10px;
      line-height: 34px;
      text-align: center;
      background-color: #ff6000;
      color: #fff;
      font-size: 12px;
      cursor: pointer;
      &.disable {
        opacity: 0.5; 
      }
    }
    .sign-up-btn {
      margin-top: 20px;
      text-align: center;
      // color: #007cff;
      color: #666;
      font-size: 12px;
      cursor: pointer;
    }
    .qrcode {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tips {
      margin-top: 9px;
      line-height: 20px;
      color: #13203d;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
    .back-btn {
      margin-top: 30px;
      width: 247px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border-radius: 2px;
      background-color: #ff6000;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
