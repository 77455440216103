



































































header {
  background-color: #fff;
  .header-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 102px;
    margin: 0 auto;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color:  #ededed;
      height: 1px;
      border-radius: 5px;
    }
    .logo-wrapper {
      width: 200px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .login {
      font-weight: 400;
      font-size: 18px;
      color: #007cff;
      cursor: pointer;
    }
  }
}
